import ContentLoader from "react-content-loader";

import { ProductCardConfiguration, SearchConfig, SkuCardConfiguration } from "@ultracommerce/react-storefront/global/src/modules/ProductListing/productListing.moduleModels";
import { SearchProduct, SearchSku } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { CalculatedDataProvider } from "../../../contexts/CalculatedDataProvider";

const ListingGridLoader = () => (
  <ContentLoader viewBox="0 0 500 250">
    <rect x="0" y="0" rx="4" ry="4" width="500" height="80" />
    <rect x="0" y="85" rx="4" ry="4" width="500" height="80" />
    <rect x="0" y="170" rx="4" ry="4" width="500" height="80" />
  </ContentLoader>
);

export interface ListingListViewProps {
  cardDisplayConfigurations: {
    skuCardConfiguration: SkuCardConfiguration;
    productCardConfiguration: ProductCardConfiguration;
  };
  isFetching: boolean;
  pageRecords: (SearchProduct | SearchSku)[];
  config: SearchConfig;
}

const ListingListView = ({ cardDisplayConfigurations, isFetching, pageRecords, config }: ListingListViewProps) => {
  const { ProductListingModule, ProductCardModule } = useElementContext();
  let cardConfig = cardDisplayConfigurations.skuCardConfiguration;

  if (config?.params?.productsListingFlag) {
    cardConfig = cardDisplayConfigurations.productCardConfiguration;
  }

  if (isFetching) return <ListingGridLoader />;

  if (pageRecords.length === 0)
    return <ProductListingModule.CoreControl.NoProductFound noProductFoundLink={config.noProductFoundLink} />;

  return (
    <div className="product-list">
      {pageRecords?.map((product, index) => {
        product.skuID = product.skuID || product.defaultSku_skuID;

        return (
          <CalculatedDataProvider key={index}>
            {config?.params?.productsListingFlag ? (
              <ProductCardModule.CoreControl.ProductCardWithDetail
                cardConfiguration={cardConfig}
                product={product as SearchProduct}
              />
            ) : (
              <ProductCardModule.CoreControl.SkuCardWithDetail
                cardConfiguration={cardConfig}
                product={product as SearchSku}
              />
            )}
          </CalculatedDataProvider>
        );
      })}
    </div>
  );
};
export { ListingListView };
