import { axios, getErrorMessage, getSdkURL, useElementContext } from "@ultracommerce/react-storefront/global";
import { useState } from "react";
import { toast } from "react-toastify";

const TradeOrderPayment = ({ poNumber, orderID, showSummary, setPurchaseOrderNumber }) => {
  const {
    CommonModule: { Modal },
  } = useElementContext();
  const [showModal, setShowModal] = useState(false);
  const [modalPONumber, setModalPONumber] = useState("");

  const handleSubmit = () => {
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/setupTradeOrderPayment`,
      data: {
        orderID: orderID,
        purchaseOrderNumber: modalPONumber,
      },
    }).then((response) => {
      if (Object.keys(response?.data?.errors || {}).length) {
        toast.error(getErrorMessage(response?.data?.errors));
      } else if (response?.data?.error && response?.data?.error !== "") {
        toast.error(response.data.error);
      } else {
        toast.success("Order Payment has been updated.");
        setShowModal(false);
        setPurchaseOrderNumber(modalPONumber);
      }
    });
  };

  return (
    <>
      <td>
        {!showSummary ? (
          <i
            className="bi bi-pencil linkit"
            onClick={() => {
              setShowModal(true);
              setModalPONumber(poNumber);
            }}
          ></i>
        ) : null}{" "}
        PO#:{" "}
      </td>
      <td className="font-weight-bold">
        {poNumber === "" ? <p className="small">Please enter PO No.</p> : poNumber}
        {showModal && (
          <Modal show={showModal} setShow={setShowModal} title="Order Payment" size="xMedium">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="text-center"
            >
              <div className="col-12 d-flex mb-2">
                <div className="col-4">
                  <label className="form-label">PO Number*</label>
                </div>
                <div className="col-8">
                  <input
                    name="purchaseOrderNumber"
                    value={modalPONumber}
                    onChange={(e) => {
                      setModalPONumber(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <button className="btn btn-primary my-2" type="submit">
                Save
              </button>
            </form>
          </Modal>
        )}
      </td>
    </>
  );
};

export default TradeOrderPayment;
