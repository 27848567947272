import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useDebounce } from "react-use";
import {
  useElementContext,
  axios,
  getSdkURL,
  getErrorMessage,
} from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";
import { useParams } from "react-router";
import { useStepQuantityInput } from "../../hooks/useStepQuantityInput";
import { useTranslation } from "react-i18next";
import { useUpdatedRequestDeliveryDate } from "../../hooks/useUpdateRequestDeliveryDate";
import { useOrderItemPrice } from "../../hooks/useOrderItemPrice";

const FilterOption = ({ orderFilter, filter, setFilter }) => {
  const [selectedBrands, setSelectedBrands] = useState(
    filter.brandID.split(",").filter((item) => item !== "")
  );
  const [selectedCategories, setSelectedCategories] = useState(
    filter.categoryID.split(",").filter((item) => item !== "")
  );

  const brandMounted = useRef();
  useEffect(() => {
    if (brandMounted.current) {
      setFilter({
        brandID: selectedBrands.join(","),
      });
    } else {
      brandMounted.current = true;
    }
  }, [selectedBrands, setFilter]);

  const categoryMounted = useRef();
  useEffect(() => {
    if (categoryMounted.current) {
      setFilter({
        categoryID: selectedCategories.join(","),
      });
    } else {
      categoryMounted.current = true;
    }
  }, [selectedCategories, setFilter]);

  /**
   *
   * @param {*} primaryID - primaryID used with checkbox selections
   * @param {*} stateVariable - current state variable
   * @param {*} setStateVariable - set method for state
   * @param {*} itemToBeAdded - item added or removed
   */
  const handleSelection = (
    primaryID,
    stateVariable,
    setStateVariable,
    itemToBeAdded = false
  ) => {
    //check if item is already added
    const itemIsAdded =
      stateVariable.filter((item) => item === primaryID).length > 0;
    //if item is to be added and it's not already added
    if (itemToBeAdded && !itemIsAdded) {
      setStateVariable([...stateVariable, primaryID]);
    } else if (!itemToBeAdded && itemIsAdded) {
      //if item is to be removed and is already added
      setStateVariable(stateVariable.filter((item) => item !== primaryID));
    }
  };

  return (
    <>
      {"brands" in orderFilter && orderFilter.brands.length > 0 && (
        <div className="col-6">
          <h6>Show Brands</h6>
          <div className="col-12 row">
            {orderFilter.brands.map((brand, index) => {
              return (
                <div className="col-6" key={`${brand.brandID}_${index}`}>
                  <label
                    htmlFor={brand.brandID}
                    className="form-check-label d-flex"
                  >
                    <input
                      type="checkbox"
                      id={brand.brandID}
                      className="form-check-input mx-1 flex-shrink-0"
                      defaultChecked={selectedBrands.includes(brand.brandID)}
                      onClick={(e) => {
                        handleSelection(
                          brand.brandID,
                          selectedBrands,
                          setSelectedBrands,
                          e.target.checked
                        );
                      }}
                    />
                    {brand.brandName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {"categories" in orderFilter && orderFilter.categories.length > 0 && (
        <div className="col-6">
          <h6>Show Category</h6>
          <div className="col-12 row">
            {orderFilter.categories.map((category, index) => {
              return (
                <div className="col-6" key={`${category.categoryID}_${index}`}>
                  <label
                    htmlFor={category.categoryID}
                    className="form-check-label d-flex"
                  >
                    <input
                      type="checkbox"
                      id={category.categoryID}
                      className="form-check-input mx-1 flex-shrink-0"
                      defaultChecked={selectedCategories.includes(
                        category.categoryID
                      )}
                      onClick={(e) => {
                        handleSelection(
                          category.categoryID,
                          selectedCategories,
                          setSelectedCategories,
                          e.target.checked
                        );
                      }}
                    />
                    {category.categoryName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const LineOrderItemQuantity = ({
  orderID,
  orderItemID,
  isLoadingPrice,
  totalPrice,
  quantity,
  removeOrderItemFromList,
  setQuoteDetail,
  showSummary,
  currencyCode,
  stepQty,
  minQty,
}) => {
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isRemovingItem, setRemovingItem] = useState(false);
  const [formatCurrency] = useCustomFormatCurrency({});
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const {
    CommonModule: { Overlay },
  } = useElementContext();
  const inputProps = useStepQuantityInput({ stepQty, minQty });

  const handleChange = (value) => {
    //do not entertain change when showing summary
    if (showSummary) return;
    setLocalQuantity(value);
  };

  const saveQuantity = (e) => {
    if (isSaveLoading) return;
    setSaveLoading(true);
    e.preventDefault();
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/updateOrderItemQuantity`,
      data: {
        orderID,
        orderItem: {
          orderItemID: orderItemID,
          quantity: localQuantity,
        },
        returnQuote: true,
      },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          toast.success("Item quantity updated successfully");
          setQuoteDetail(response?.data?.quote);
        } else {
          toast.error(getErrorMessage(response?.data?.failureActions));
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const removeOrderItem = (orderID, orderItemID) => {
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/removeOrderItem`,
      data: {
        orderID,
        orderItemID,
        returnJSONObjects: "cart",
        returnQuote: true,
        isQuote: true,
      },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          toast.success("Item removed successfully");
          removeOrderItemFromList();
          setQuoteDetail(response?.data?.quote);
        } else toast.error(getErrorMessage(response?.data?.failureActions));
      })
      .finally(() => {
        setRemovingItem(true);
      });
  };

  const cancelQuantity = () => {
    handleChange(quantity);
  };

  return (
    <>
      <td>
        <form onSubmit={saveQuantity} className="relative my-2">
          <Overlay
            active={isSaveLoading}
            spinner
            styles={{ overlay: (base) => ({ ...base, width: "100px" }) }}
          >
            <input
              {...inputProps}
              name="qty"
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              value={localQuantity}
              type="number"
              className="form-control ps-3"
              style={{ width: "100px", borderRadius: "30px" }}
              required
            />
          </Overlay>
          {parseInt(localQuantity) !== parseInt(quantity) && !isSaveLoading && (
            <span>
              <input
                className="text-primary small text-underline linkit small pe-2 border-0 bg-transparent"
                type="submit"
                value="Save"
              />
              <span
                className="text-primary small float-right text-underline linkit"
                title="Cancel"
                onClick={() => cancelQuantity()}
              >
                Cancel
              </span>
            </span>
          )}
        </form>
      </td>
      <td style={{ paddingTop: "1.5rem" }}>
        <b>{formatCurrency(totalPrice, currencyCode)}</b>
        {!showSummary && (
          <>
            <br />
            {isRemovingItem ? (
              <span
                className="spinner-border spinner-border-sm p-2 text-primary"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span
                className="text-primary small text-underline btn p-0"
                onClick={(e) => {
                  const confirmed = window.confirm(
                    "Are you sure you want to delete this item?"
                  );

                  if (confirmed) {
                    removeOrderItem(orderID, orderItemID);
                  }
                }}
              >
                Remove
              </span>
            )}
          </>
        )}
      </td>
    </>
  );
};

const LineOrderItem = ({
  orderItem,
  orderID,
  removeOrderItemFromList,
  showSummary,
  setQuoteDetail,
}) => {
  const { t } = useTranslation();
  const [formatCurrency] = useCustomFormatCurrency({});
  let { id } = useParams();
  const { CartModule } = useElementContext();

  const {
    data: { leadTime },
    actions: { saveRequestedDeliveryDate },
    render: { renderActionButton, renderInput },
  } = useUpdatedRequestDeliveryDate({ orderItem, orderID: id });
  const { unitPrice, totalPrice, surcharge, showSurcharge } =
    useOrderItemPrice(orderItem);
  return (
    <>
      <tr className="py-2">
        <td width={"40%"}>
          <span className="font-weight-bold d-block">
            {orderItem.sku?.product?.productName}
          </span>
          <span className="small d-block my-2">
            {t("frontend.quote.modelNumber")}: {orderItem?.modelNumber}
          </span>
          <span className="small d-block my-2">
            {t("frontend.quote.leadTime", { leadTime })}
          </span>
          {orderItem?.customerMaterialNumber && (
            <span className="small d-block my-2">
              {t("frontend.quote.customMaterialNumber")}:{" "}
              {orderItem?.customerMaterialNumber}
            </span>
          )}
          {orderItem.improCustomCode && (
            <span className="small d-block my-2">
              {t("frontend.quote.customCode")}: {orderItem?.improCustomCode}
            </span>
          )}
          <CartModule.CoreControl.OrderItemSkuConfigurations
            skuConfigurations={orderItem?.skuConfigurations}
          />
        </td>
        <td>
          <form
            className="my-2 form-group d-flex flex-column"
            onSubmit={saveRequestedDeliveryDate}
          >
            {renderInput()}
            <span className="small">{renderActionButton()}</span>
          </form>
        </td>
        <td style={{ paddingTop: "1.5rem" }}>
          {formatCurrency(unitPrice, orderItem?.currencyCode)}
        </td>
        {showSurcharge && (
          <td style={{ paddingTop: "1.5rem" }}>
            {formatCurrency(surcharge, orderItem?.currencyCode)}
          </td>
        )}
        <LineOrderItemQuantity
          setQuoteDetail={setQuoteDetail}
          removeOrderItemFromList={removeOrderItemFromList}
          orderItemID={orderItem.orderItemID}
          orderID={orderID}
          quantity={orderItem.quantity}
          totalPrice={totalPrice}
          key={orderItem.orderItemID}
          showSummary={showSummary}
          currencyCode={orderItem?.currencyCode}
          stepQty={orderItem.stepQty || "1"}
          minQty={orderItem.minQty || "1"}
        />
      </tr>
    </>
  );
};

const DraftOrderTab = ({
  showFilters,
  orderFilters,
  orderID,
  filter,
  setFilter,
  getOrderInfo,
  setQuoteDetail,
  showSummary,
  quote
}) => {
  const { orderItems, currencyCode, orderTotalSummary } = quote || {};
  const { t } = useTranslation();
  const [formatCurrency] = useCustomFormatCurrency({});
  const [localOrderItems, setLocalOrderItems] = useState(orderItems);
  const [keyword, setKeyword] = useState(filter.keyword ?? "");

  const keywordMounted = useRef();
  useDebounce(
    () => {
      if (keywordMounted.current) {
        setFilter({
          keyword: keyword,
        });
      } else {
        keywordMounted.current = true;
      }
    },
    800,
    [keyword]
  );

  useEffect(() => {
    setLocalOrderItems(orderItems);
  }, [orderItems]);

  const removeOrderItemFromList = (orderItem) => {
    const updatedItems = localOrderItems.filter(
      (item, i) => item.orderItemID !== orderItem.orderItemID
    );
    setLocalOrderItems(updatedItems);
    getOrderInfo({ isFetching: false });
  };

  return (
    <>
      {showFilters && (
        <div className="bg-light p-4 my-3 row">
          <div className="col-3">
            <label className="label">Search</label>
            <input
              name="searchOrder"
              type="text"
              className="form-control"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
          <div className="col-9 row">
            {orderFilters?.length > 0 &&
              orderFilters?.map((orderFilter, index) => {
                return (
                  <React.Fragment key={"filterDOT" + index}>
                    <FilterOption
                      orderFilter={orderFilter}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      )}
      {localOrderItems && localOrderItems.length > 0 ? (
        <div className="table-responsive">
          <table className="table mt-3 mb-0">
            <thead>
              <tr>
                <th style={{ minWidth: "250px" }}>Item</th>
                <th style={{ minWidth: "100px" }}>Customer Requested Date</th>
                <th style={{ minWidth: "100px" }}>Unit Price</th>
                {currencyCode === "EUR" && (
                  <th style={{ minWidth: "100px" }}>
                    {t("frontend.cart.surcharge")}
                  </th>
                )}
                <th style={{ minWidth: "75px" }}>Quantity</th>
                <th style={{ minWidth: "75px" }}>Line Total</th>
              </tr>
            </thead>
            <tbody>
              {localOrderItems &&
                localOrderItems.map((orderItem) => {
                  return (
                    <LineOrderItem
                      setQuoteDetail={setQuoteDetail}
                      removeOrderItemFromList={() =>{
                        removeOrderItemFromList(orderItem)
                      }}
                      orderItem={orderItem}
                      orderID={orderID}
                      key={orderItem.orderItemID}
                      showSummary={showSummary}
                    />
                  );
                })}
            </tbody>
          </table>
          <div className="bg-light text-end p-2 my-2 font-weight-bold">
            <span className="me-4">
              <span className="me-4">Subtotal:</span>{" "}
              {formatCurrency(orderTotalSummary?.totalAfterSurcharge, currencyCode)}
            </span>
          </div>{" "}
        </div>
      ) : (
        <table className="table mt-3 mb-0">
          <tbody>
            <tr>
              <td colSpan="6" className="text-center">
                No items found
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default DraftOrderTab;
