import { useState, memo, useCallback, useMemo, useRef } from "react";
import { useCSVReader, useCSVDownloader } from "react-papaparse";
import { toast } from "react-toastify";
import { chunk } from "lodash";
import { useTranslation } from "react-i18next";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";
import {
  getErrorMessage,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { getSdkURL, axios } from "@ultracommerce/react-storefront/global";
import { bulkCalculatePrice } from "../../actions/cartActions";
import { useSelector } from "react-redux";
import {
  calMinDeliveryDate,
  calStepAndMinQuantity,
  modelNumberToSkuConfiguration,
} from "../../utils";
import { useStepQuantityInput } from "../../hooks/useStepQuantityInput";

const skusPerPage = 10;

const CSVSkuItem = memo(
  ({
    sku,
    idx,
    handleSelectedSkuRemove,
    handleRequestedDateChange,
    handleQuantityChange,
    handleCalculatePrice,
  }) => {
    const [formatCurrency] = useCustomFormatCurrency({});
    const { calculatedData, modifiers, skuConfiguration } = sku.skuData || {};
    const { stepQty, minQty } = calStepAndMinQuantity(
      modifiers,
      skuConfiguration
    );
    const inputProps = useStepQuantityInput({ stepQty, minQty });
    const isValidQuantity =
      !!sku.skuData && sku.qty >= minQty && sku.qty % stepQty === 0;
    const isValidPrice =
      sku.qty === calculatedData?.modelPriceInfo?.quantity && isValidQuantity;
    return (
      <tr className="align-items-center pb-2 border-bottom">
        <td>{sku.lineNumber + 1}</td>
        <td>{sku.skuCode}</td>
        <td>
          <input
            className="form-control"
            value={sku.requestedDeliveryDate}
            onChange={(e) => {
              handleRequestedDateChange(e.target.value, idx);
            }}
            type="date"
            min={calculatedData?.leadDeliveryDate}
            style={{ width: "auto" }}
          />
        </td>
        <td>{sku.customerMaterialNumber}</td>
        <td>
          {calculatedData?.modelPriceInfo?.skuConfigurationPrice
            ?.effectiveLeadTime
            ? `${
                calculatedData.modelPriceInfo.skuConfigurationPrice
                  .effectiveLeadTime
              } ${
                calculatedData.modelPriceInfo.skuConfigurationPrice
                  .effectiveLeadTime === 1
                  ? "week"
                  : "weeks"
              }`
            : ""}
        </td>
        <td>
          <input
            {...inputProps}
            required
            type="number"
            className="form-control"
            name="qty"
            value={sku.qty}
            onChange={(e) => {
              handleQuantityChange(e.target.value, idx);
            }}
          />
        </td>
        {isValidPrice ? (
          <>
            <td>
              {formatCurrency(
                calculatedData?.modelPriceInfo
                  ?.modelUnitPriceAfterDiscountAfterSurchargeAfterTax,
                calculatedData?.modelPriceInfo?.currencyCode
              )}
            </td>
            <td>
              {formatCurrency(
                calculatedData?.modelPriceInfo
                  ?.totalModelPriceAfterDiscounAfterSurcharge,
                calculatedData?.modelPriceInfo?.currencyCode
              )}
            </td>
          </>
        ) : (
          <td colSpan={2}>
            <div className="btn-group-sm">
              {sku.isCalculatingPrice ||
              (!handleCalculatePrice && isValidQuantity) ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    handleCalculatePrice(sku, idx);
                  }}
                  disabled={!isValidQuantity}
                >
                  Calculate Price
                </button>
              )}
            </div>
          </td>
        )}
        <td
          className="text-danger"
          style={{ width: "15%", textWrap: "balance" }}
        >
          {sku.errorMessages.join(",")}
        </td>
        <td>
          <button
            className="btn"
            disabled={!handleSelectedSkuRemove}
            onClick={(e) => {
              if (window.confirm("Do you want to remove this item ?")) {
                handleSelectedSkuRemove?.(idx);
              }
            }}
          >
            <i className="bi bi-trash3 text-primary"></i>
          </button>
        </td>
      </tr>
    );
  }
);

const CSVAddTab = ({ orderID, getOrderInfo, setAddingProduct }) => {
  const { t } = useTranslation();
  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();
  const [formatCurrency] = useCustomFormatCurrency({});
  const removeFileButtonRef = useRef();
  const {
    CommonModule: { Overlay },
  } = useElementContext();

  const [addingToCart, setAddingToCart] = useState(false);
  const [progress, setProgress] = useState({});

  const [skuData, setSkuData] = useState([]);
  const [isCalculatingTotalPrice, setCalculatingTotalPrice] = useState(false);
  const { addressZoneName } = useSelector((state) => state.userReducer);
  const fetchSku = useCallback((skuCode) => {
    return fetch(
      `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/skus/bySkuCode/${skuCode}`
    )
      .then(async (data) => data.json())
      .catch((e) => {
        return null;
      });
  }, []);

  const fetchSkuMetaData = useCallback(
    async (skuCodes) => {
      const chunkCode = chunk(skuCodes, skusPerPage);
      const skuMetaData = {};
      for (const codes of chunkCode) {
        await Promise.all(
          codes.map((skuCode) => {
            return fetchSku(skuCode).then(
              (data) => (skuMetaData[skuCode] = data)
            );
          })
        );
      }
      return skuMetaData;
    },
    [fetchSku]
  );

  const checkRowError = useCallback(
    (row) => {
      row.errorMessages = [];
      if (
        !row.skuData ||
        row.skuData.modifiers.length !==
          Object.keys(row.skuData.skuConfiguration).length ||
        !row.skuData.calculatedData
      ) {
        row.errorMessages.push("Invalid Model Number");
        return row;
      }
      if (
        row.requestedDeliveryDate &&
        !/(\d{4}-(\d{2})-(\d{2}))/.test(row.requestedDeliveryDate)
      ) {
        row.errorMessages.push(
          "Invalid Date Format. Please Ensure Your CSV Matches The Correct Date Format (yyyy-mm-dd'T'HH:nn:ssXXX)"
        );
      } else if (
        !row.requestedDeliveryDate ||
        new Date(row.skuData?.calculatedData?.leadDeliveryDate).getTime() >
          new Date(row.requestedDeliveryDate).getTime()
      ) {
        row.errorMessages.push(
          `Customer Requested Date Must Be ${row.skuData?.calculatedData?.leadDeliveryDate} Or Later`
        );
      }
      if (row.skuData.calculatedQATS < row.qty) {
        row.errorMessages.push(
          t("frontend.draftOrder.csvAdd.maxQtyAvailable", {
            maxQATS: row.skuData.calculatedQATS,
          })
        );
      }
      const { stepQty, minQty } = calStepAndMinQuantity(
        row.skuData.modifiers,
        row.skuData.skuConfiguration
      );
      if (parseInt(row.qty, 10) < parseInt(minQty, 10)) {
        row.errorMessages.push(
          `Invalid Quantity. Min Quantity For Pallet: ${minQty}`
        );
      } else if (parseInt(row.qty, 10) % parseInt(stepQty, 10) !== 0) {
        row.errorMessages.push(
          `Invalid Quantity. Step Quantity For Pallet: ${stepQty}`
        );
      }
      return row;
    },
    [t]
  );

  const handleCalculatePrice = useCallback(
    (row, idx) => {
      if (!(row.skuData && row.skuData.skuConfiguration)) {
        return;
      }

      setSkuData((prevState) => {
        const newState = [...prevState];
        newState[idx] = { ...row, isCalculatingPrice: true };
        return newState;
      });

      return bulkCalculatePrice([
        {
          skuID: row.skuData.skuID,
          quantity: parseInt(row.qty, 10),
          modelNumber: row.skuCode,
        },
      ])
        .then(async ({ data }) => {
          const priceData = data.prices[0];
          const { minDeliveryDate } = calMinDeliveryDate(
            priceData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime
          );
          row.skuData = {
            ...row.skuData,
            calculatedData: { ...priceData, leadDeliveryDate: minDeliveryDate },
          };
          setSkuData((prevState) => {
            const newState = [...prevState];
            newState[idx] = {
              ...checkRowError(row),
              isCalculatingPrice: false,
            };
            return newState;
          });
        })
        .catch((e) => {
          setSkuData((prevState) => {
            const newState = [...prevState];
            newState[idx] = {
              ...checkRowError(row),
              isCalculatingPrice: false,
            };
            return newState;
          });
        });
    },
    [checkRowError]
  );

  const fetchAllConfiguratedSkus = useCallback(async (imported) => {
    for (const currentChunk of chunk(imported, skusPerPage)) {
      const validItem = currentChunk.filter(
        (row) =>
          !!row.skuData?.skuConfiguration &&
          (!row.skuData.calculatedData ||
            row.skuData.calculatedData.modelPriceInfo.quantity !== row.qty)
      );
      if (validItem.length) {
        await bulkCalculatePrice(
          validItem.map((row) => ({
            skuID: row.skuData.skuID,
            quantity: parseInt(row.qty, 10),
            modelNumber: row.skuCode,
          }))
        ).then(({ data }) => {
          validItem.forEach((row, index) => {
            const priceData = data.prices[index];
            const { minDeliveryDate } = calMinDeliveryDate(
              priceData?.modelPriceInfo?.skuConfigurationPrice
                ?.effectiveLeadTime
            );
            row.skuData = {
              ...row.skuData,
              calculatedData: {
                ...priceData,
                leadDeliveryDate: minDeliveryDate,
              },
            };
          });
        });
      }
      setProgress((prevState) => ({
        ...prevState,
        currentCount: prevState.currentCount + currentChunk.length,
      }));
    }
    return imported;
  }, []);

  const renderCSVReader = () => {
    return (
      <CSVReader
        onUploadAccepted={async (results) => {
          setSkuData([]);
          setProgress({
            currentCount: 0,
            total: 0,
            complete: false,
          });
          if(results.data.length > 100){
            removeFileButtonRef.current?.click();
            return toast.error("File is too large. Max file size is 100 rows");
          }
          const imported = results.data
            .map((row, lineNumber) => {
              const dateString = (row[2] || "").split("T")[0];
              return {
                lineNumber,
                skuCode: row[0],
                skuData: null,
                qty: parseInt(row[1], 10),
                plannedDeliveryDate: dateString,
                requestedDeliveryDate: dateString,
                customerMaterialNumber: row[3],
                errorMessages: [],
              };
            })
            .filter((sku) => sku.lineNumber !== 0 && sku.skuCode !== "");
          setProgress({
            currentCount: 0,
            total: imported.length,
            complete: false,
          });
          const allSkuCode = [
            ...new Set(imported.map(({ skuCode }) => skuCode.slice(0, 3))),
          ];
          const skuMetaData = await fetchSkuMetaData(allSkuCode);
          imported.forEach((sku) => {
            if (!skuMetaData[sku.skuCode.slice(0, 3)]) return;
            const skuData = { ...skuMetaData[sku.skuCode.slice(0, 3)] };
            skuData.modifiers =
              skuData?.modifiers ||
              (addressZoneName === "US"
                ? skuData?.modifiersUS
                : skuData?.modifiersEU);
            const skuConfiguration = modelNumberToSkuConfiguration(
              sku.skuCode,
              skuData.modifiers
            );
            if (
              Object.keys(skuConfiguration).length !== skuData.modifiers.length
            ) {
              return;
            }
            skuData.skuConfiguration = skuConfiguration;
            sku.skuData = skuData;
          });
          const allSkus = await fetchAllConfiguratedSkus(imported);
          setProgress((prevState) => ({
            ...prevState,
            complete: true,
          }));
          setSkuData(allSkus.map(checkRowError));
        }}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
          <>
            <div className="csvadd-csvreadercontainer p-3 mt-2 d-flex flex-wrap gap-2">
              <div className="csvadd-buttons-container">
                <button
                  className="upload btn btn-outline-secondary"
                  type="button"
                  {...getRootProps()}
                >
                  {t("frontend.draftOrder.csvAdd.button.browse_file")}
                </button>
                <div className="filename">
                  {acceptedFile && acceptedFile.name}
                </div>
                <button
                  className="clear btn btn-outline-secondary"
                  onClick={() => {
                    setSkuData([]);
                    removeFileButtonRef.current?.click();
                  }}
                >
                  {t("frontend.draftOrder.csvAdd.button.clear")}
                </button>
              </div>
              <CSVDownloader
                config={{
                  delimiter: ";",
                }}
                filename="CSV Template"
                data={() => {
                  const { minDeliveryDate } = calMinDeliveryDate(20);
                  return [
                    [
                      "Model Number",
                      "Quantity",
                      "Customer Requested Date",
                      "Customer Material Number",
                    ],
                    [
                      "125012JLBC4BAAAAS",
                      "20",
                      new Date(minDeliveryDate).toISOString(),
                      "99999",
                    ],
                  ];
                }}
              >
                <button className="btn btn-outline-secondary">
                  <i
                    className="bi bi-filetype-csv"
                    style={{ marginRight: "0.5rem" }}
                  ></i>
                  {t("frontend.draftOrder.csvAdd.button.download")}
                </button>
              </CSVDownloader>
            </div>
            {progress.total > 0 && !progress.complete && (
              <div className="alert alert-info mt-2 mb-2">
                {addingToCart
                  ? t(
                      "frontend.draftOrder.csvAdd.addingToCartProgress",
                      progress
                    )
                  : t("frontend.draftOrder.csvAdd.progress", progress)}
              </div>
            )}
            <button
              {...getRemoveFileProps()}
              className="d-none"
              ref={removeFileButtonRef}
            >
              Remove
            </button>
          </>
        )}
      </CSVReader>
    );
  };

  const handleQuantityChange = useCallback(
    (qty, idx) => {
      setSkuData((prevState) => {
        const newState = [...prevState];
        newState[idx] = checkRowError({
          ...newState[idx],
          qty: parseInt(qty, 10),
        });
        return newState;
      });
    },
    [checkRowError]
  );

  const handleRequestedDateChange = useCallback(
    (newDate, idx) => {
      setSkuData((prevState) => {
        const newState = [...prevState];
        newState[idx] = checkRowError({
          ...newState[idx],
          requestedDeliveryDate: newDate,
        });
        return newState;
      });
    },
    [checkRowError]
  );

  const handleSelectedSkuRemove = useCallback((idx) => {
    setSkuData((prevState) => {
      const newState = [...prevState];
      newState.splice(idx, 1);
      return newState;
    });
  }, []);

  const addToCart = async (orderItem) => {
    if (addingToCart) return;

    const skus = orderItem.map((item) => ({
      skuID: item.skuData.skuID,
      quantity: parseInt(item.qty, 10),
      customerMaterialNumber: item.customerMaterialNumber,
      requestedDeliveryDate: item.requestedDeliveryDate,
      plannedDeliveryDate: item.requestedDeliveryDate,
      currencyCode: item.skuData?.calculatedData?.modelPriceInfo?.currencyCode,
      modelNumber: item.skuCode,
    }));

    setAddingToCart(true);
    getOrderInfo({ makeRequest: false });
    setAddingProduct(true);
    setProgress({
      currentCount: 0,
      total: skus.length,
      complete: false,
    });

    try {
      for (const currentChunk of chunk(skus, skusPerPage)) {
        const response = await axios({
          method: "POST",
          url: `${getSdkURL()}api/scope/addOrderItemsV2`,
          data: {
            orderID,
            skus: currentChunk,
          },
        });
        if (
          response?.status !== 200 ||
          response?.data?.failureActions.length !== 0
        ) {
          toast.error(getErrorMessage(response?.data?.failureActions));
          throw response?.data?.failureActions;
        }
        setProgress((prevState) => ({
          ...prevState,
          currentCount: prevState.currentCount + currentChunk.length,
        }));
      }
      toast.success("Items added successfully");
      getOrderInfo();
      setSkuData([]);
      removeFileButtonRef.current?.click();
    } catch {
      getOrderInfo({
        isFetching: false,
        makeRequest: false,
        isLoaded: true,
      });
    } finally {
      setAddingProduct(false);
      setAddingToCart(false);
      setProgress((prevState) => ({
        ...prevState,
        complete: true,
      }));
    }
  };

  const onCalculatedTotalPriceClick = async () => {
    setCalculatingTotalPrice(true);
    const newSkuData = await fetchAllConfiguratedSkus(skuData);
    setSkuData([...newSkuData]);
    setCalculatingTotalPrice(false);
  };

  const addAllToCart = () =>
    addToCart(skuData.filter((sku) => !sku.errorMessages.length));
  const { successfulCount, errorCount, totalPrice, isValidPrice } =
    useMemo(() => {
      let isValidPrice = true;
      const totalPrice = formatCurrency(
        skuData
          .filter((sku) => !sku.errorMessages.length)
          .reduce((acc, sku) => {
            if (
              sku.qty !== sku.skuData.calculatedData?.modelPriceInfo.quantity
            ) {
              isValidPrice = false;
            }
            acc =
              acc +
              sku.skuData.calculatedData?.modelPriceInfo
                ?.totalModelPriceAfterDiscounAfterSurcharge;
            return acc;
          }, 0)
      );
      return {
        successfulCount: skuData.filter((sku) => !sku.errorMessages.length)
          .length,
        errorCount: skuData.filter((sku) => sku.errorMessages.length).length,
        totalPrice,
        isValidPrice,
      };
    }, [formatCurrency, skuData]);

  const isCalculatingPrice = useMemo(
    () => skuData.some(({ isCalculatingPrice }) => isCalculatingPrice),
    [skuData]
  );

  return (
    <>
      {renderCSVReader()}
      <Overlay
        fadeSpeed={0}
        active={progress.total > 0 && !progress.complete}
        spinner
        text="Do not close this window, this might take a minute to process."
        styles={{
          spinner: !skuData.length
            ? (base) => ({
                ...base,
                "& svg circle": {
                  stroke: "var(--uc-primarycolor)",
                },
              })
            : undefined,
          overlay: !skuData.length
            ? (base) => ({ ...base, color: "unset", backgroundColor: "unset" })
            : undefined,
          content: (base) => ({
            ...base,
            position: "sticky",
            top: 0,
            bottom: 0,
            padding: "2rem",
          }),
        }}
      >
        {!skuData.length ? (
          <div
            style={{
              height: progress.total > 0 && !progress.complete ? 200 : 0,
            }}
          ></div>
        ) : (
          <>
            <div className="csvAdd-completedProgress alert alert-secondary py-2 my-2 d-flex flex-wrap align-items-center justify-content-between">
              <div className="btn-group-sm">
                {t("frontend.draftOrder.csvAdd.completedProgress", {
                  successfulCount,
                  errorCount,
                  totalPrice: isValidPrice ? totalPrice : "",
                })}
                {!isValidPrice ? (
                  <>
                    {": "}
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={
                        isCalculatingTotalPrice
                          ? undefined
                          : onCalculatedTotalPriceClick
                      }
                    >
                      {isCalculatingTotalPrice ? (
                        <div
                          className="spinner-border spinner-border-sm ml-3"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Calculate Total Price"
                      )}
                    </button>
                  </>
                ) : null}
              </div>
              <button
                onClick={addAllToCart}
                className="btn btn-primary"
                disabled={!successfulCount}
              >
                <span className="d-inline-block me-2">
                  {t("frontend.draftOrder.csvAdd.addToCart", {
                    successfulCount,
                  })}
                </span>
                {addingToCart && (
                  <div
                    className="spinner-border spinner-border-sm ml-3"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
            <div className="table-responsive">
              <table className="w-100">
                {skuData.length && (
                  <thead>
                    <tr>
                      <th>CSV Line</th>
                      <th>Model Number</th>
                      <th>Customer Requested Date</th>
                      <th>Customer Material Number</th>
                      <th>Lead Time</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total Price</th>
                      <th>Issue</th>
                      <th></th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {skuData.length &&
                    skuData.map((sku, idx) => (
                      <CSVSkuItem
                        key={sku.lineNumber}
                        sku={sku}
                        idx={idx}
                        handleSelectedSkuRemove={
                          isCalculatingPrice || isCalculatingTotalPrice
                            ? undefined
                            : handleSelectedSkuRemove
                        }
                        handleRequestedDateChange={handleRequestedDateChange}
                        handleQuantityChange={handleQuantityChange}
                        handleCalculatePrice={
                          isCalculatingTotalPrice
                            ? undefined
                            : handleCalculatePrice
                        }
                      />
                    ))}
                </tbody>
              </table>
            </div>
            {skuData.length > 20 && (
              <div className="alert alert-secondary mt-3 py-2 d-flex flex-wrap align-items-center justify-content-between">
                <span className="d-inline-block me-2">
                  {t("frontend.draftOrder.csvAdd.addToCart", {
                    successfulCount,
                  })}
                </span>
                <button
                  onClick={addAllToCart}
                  className="btn btn-primary"
                  disabled={!successfulCount}
                >
                  <span className="pr-3">
                    {t("frontend.draftOrder.csvAdd.addToCart", {
                      successfulCount,
                    })}
                  </span>
                  {addingToCart && (
                    <div
                      className="spinner-border spinner-border-sm ml-3"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </Overlay>
    </>
  );
};

export default CSVAddTab;
