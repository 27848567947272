import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  initApp,
  CommonModule,
  CartModule,
  ProductCardModule,
  ProductModule,
  PageModule,
  ProductListingModule,
  LocationModule
} from "@ultracommerce/react-storefront/global";
import { AppSwitcher } from './AppSwitcher'
import "./i18n";
import i18next from "i18next";
import store from "./createStore";
import "./assets/theme";
import preload from "./preload";
import { RouteService } from './services/routeService.class'
import { HeaderTopLeft } from "./components/Header/HeaderLayout/HeaderTopLeft";
import { HeaderTopRight } from './components/Header/HeaderLayout/HeaderTopRight'
import { SearchBar } from './components/Header/SearchBar/SearchBar.component'
import { BasicCard } from './components/CMS/ContentCard/ContentCardTypes'
import { AccountLogin } from './components/Account/AccountLogin/AccountLogin'
import { SocialLinks } from './components/SocialLinks/SocialLinks'
import { ContentStackMenu } from './components/Footer/ContentStackMenu'
import { FooterStyleOne } from './components/Footer/Footer-style-one'
import { Video } from './components/Video/Video'
import { ContentBlock } from './components/ContentBlock/ContentBlock'
import { DynamicForm } from './components/DynamicForm/DynamicForm'
import { SubmitButton } from './components/DynamicForm/component/SubmitButton'
import FormInputLayout from './components/DynamicForm/component/FormInputLayout'
import FormTextField from './components/DynamicForm/inputs/formTextField'
import { HTMLcontent } from './components/htmlContent/htmlContent'
import SubscribeForm from './components/SubscribeForm'
import { ActionBanner } from './components/ActionBanner/ActionBanner'
import CallToActionBtn from './components/CallToActionBtn'
import { BlogListing } from './components/Blog/BlogListing'
import { LanguagePicker } from './components/LanguagePicker/LanguagePicker'
import { LocationAddress } from './modules/Location/Controls/LocationAddress'
import { WhereToBuyLocationCard } from './modules/Location/Controls/WhereToBuyLocationCard'
import { ProductMultiCartButtons } from './modules/Product/Controls/ProductMultiCartButtons'
import { ProductCardWithDetail, SkuCardWithDetail } from './modules/ProductCard/Controls/ProductCardWithDetail'
import { ProductCardButton } from './modules/ProductCard/Controls/ProductCardButton'
import ProductDetailPage from './pages/ProductDetailsPage/ProductDetailsPage.page'
import { WhereToBuyLocation } from './modules/Location/Components/WhereToBuyLocation/WhereToBuyLocation.component'
import { AddProductToQuoteModal } from './modules/Product/Components/AddProductToQuoteModal/AddProductToQuoteModal.component'
import { ProductAttributes } from './modules/Product/Components/ProductAttributes/ProductAttributes.component'
import { ProductModifiers } from './modules/Product/Controls/ProductModifiers'
import { AccountOrderHistory } from './components/Account/AccountOrderHistory/AccountOrderHistory'
// import { AccountQuotes } from './components/Account/AccountQuotes/AccountQuotes'
import { AccountOrderDetail } from './components/Account/AccountOrderDetail/AccountOrderDetail'
import { OrderToolbar } from './components/Account/AccountOrderDetail/OrderToolbar'
import { AccountContent } from './components/Account/AccountContent/AccountContent'
import { AccountProfile } from './components/Account/AccountProfile/AccountProfile'
import { AccountLayout } from './components/Account/AccountLayout/AccountLayout'
import { HeaderContentFour } from './components/Header/HeaderLayout/HeaderContentFour'
import { HeaderContent } from "./components/Header/HeaderLayout/HeaderContent";
import { SkuCard, ProductCard } from './modules/ProductCard/Controls/ProductCardCardView'
import { DropdownButtons } from './modules/ProductCard/Controls/DropdownButtons'
import { ProductCardActions } from './modules/ProductCard/Components/ProductCardActions/ProductCardActions.component'
import { ListingListView } from './modules/ProductListing/Controls/ListingListView'
import Blog from './pages/Blog/Blog'
import { MiniCartItem } from './components/Header/MiniCart/MiniCartItem'
import { OrderShipments, OrderFulfilments } from './components/Account/AccountOrderDetail/OrderShipments'
import { AccountBubble } from './components/Header/MenuItem/AccountBubble'
import { PaymentList } from './components/Checkout/Payment/PaymentList'
import { CartLineItem } from './modules/Cart/Components/CartLineItem/CartLineItem.component'
import { HomeAboutUs } from './components/HomeAboutUs/HomeAboutUs'
import { ListingBanner } from './components/ListingBanner/ListingBanner'
import { ContentSlider } from './components/ContentSlider/ContentSlider'
import { ResourceLinkListing } from './components/Resources/ResourceCardLayoutTypes'
import { CustomProductTypeList } from "./modules/Product/Components/ProductTypeList/ProductTypeList.component";
import { ProductForm } from "./modules/Product/Components/ProductForm/ProductForm.component";
import { ProductDetailHeading } from "./modules/Product/Components/ProductDetailHeading/ProductDetailHeading.component";
import OverviewDraftOrders from "./components/Account/AccountOverview/OverviewDraftOrders";
import DraftOrder from "./pages/DraftOrder/DraftOrder";
import DraftOrderHeader from "./components/DraftOrder/DraftOrderHeader";
import QuickAddTab from "./components/DraftOrder/QuickAddTab";
import { ShippingFulfillment } from './components/Checkout/Fulfilment/ShippingFulfillment';
import { FulfilmentAddressSelector } from './components/Checkout/Fulfilment/FulfilmentAddressSelector';
import MyAccount from './pages/MyAccount/MyAccount'
import { SelectedSkuConfiguration } from './components/DraftOrder/SelectedSkuConfiguration'
import AddProductsTab from './components/DraftOrder/AddProductsTab'
import DraftOrderTab from './components/DraftOrder/DraftOrderTab'
import { FulfillmentList } from "./components/Checkout/Fulfilment/FulfillmentList";
import { ProjectProductService as ProductService } from "./services/productService.class";
import { ProductModifiersModal } from './modules/Product/Controls/ProductModifiersModal'
import ProductFilterGroupList from "./components/DraftOrder/DraftOrderProductCard/ProductFilterGroupList";
import { AccountAddresses } from "./components/AccountAddresses/AccountAddresses";
import { LocationDetail } from "./modules/Location/Controls/LocationDetail";
import TradeOrderPayment from "./components/DraftOrder/TradeOrderPayment";
import { LocationMap } from "./modules/Location/Controls/LocationMap";
import { DraftOrderProductCard } from "./components/DraftOrder/DraftOrderProductCard/DraftOrderProductCard";
import CSVAddTab from "./components/DraftOrder/CSVAddTab";
import { ProductQuantityInput } from "./modules/Product/Controls/ProductQuantityInput";
import { ForgotPassword } from './components/Account/ForgotPassword/ForgotPassword'
import { ListingSidebar } from "./modules/ProductListing/Controls/ListingSidebar";
import { FacetFilter } from "./modules/ProductListing/Components/FacetFilter/FacetFilter.component";
import { OrderItemSkuConfigurations } from "./modules/Cart/Controls/OrderItemSkuConfigurations";


CommonModule.HeaderTopRight = HeaderTopRight
CommonModule.HeaderTopLeft = HeaderTopLeft
CommonModule.SearchBar = SearchBar
CommonModule.HeaderContent = HeaderContent
CommonModule.BasicCard = BasicCard
CommonModule.AccountLogin = AccountLogin
CommonModule.SocialLinks = SocialLinks
CommonModule.ContentStackMenu = ContentStackMenu
CommonModule.FooterStyleOne = FooterStyleOne
CommonModule.Video = Video
CommonModule.ContentBlock = ContentBlock
CommonModule.FormTextField = FormTextField
CommonModule.DynamicForm = DynamicForm
CommonModule.SubmitButton = SubmitButton
CommonModule.FormInputLayout = FormInputLayout
CommonModule.HTMLcontent = HTMLcontent
CommonModule.SubscribeForm = SubscribeForm
// @ts-ignore
CommonModule.ActionBanner = ActionBanner
CommonModule.CallToActionBtn = CallToActionBtn
CommonModule.BlogListing = BlogListing
CommonModule.LanguagePicker = LanguagePicker
CommonModule.AccountOrderHistory = AccountOrderHistory
CommonModule.AccountProfile = AccountProfile
CommonModule.HeaderContentFour = HeaderContentFour
CommonModule.MiniCartItem = MiniCartItem
CommonModule.OrderShipments = OrderShipments
// @ts-ignore
CommonModule.OrderToolbar = OrderToolbar
// CommonModule.OrderItem = OrderItem
// @ts-ignore
CommonModule.OrderFulfilments = OrderFulfilments
CommonModule.AccountBubble = AccountBubble
CommonModule.PaymentList = PaymentList
CommonModule.ListingBanner = ListingBanner
CommonModule.ContentSlider = ContentSlider
CommonModule.ResourceLinkListing = ResourceLinkListing
CommonModule.AccountOrderDetail = AccountOrderDetail
CommonModule.OverviewDraftOrders = OverviewDraftOrders
// @ts-ignore
CommonModule.DraftOrderHeader = DraftOrderHeader
CommonModule.DraftOrderProductCard = DraftOrderProductCard
CommonModule.ShippingFulfillment = ShippingFulfillment
CommonModule.FulfilmentAddressSelector = FulfilmentAddressSelector
CommonModule.SelectedSkuConfiguration = SelectedSkuConfiguration
// @ts-ignore
CommonModule.AddProductsTab = AddProductsTab
// @ts-ignore
CommonModule.DraftOrderTab = DraftOrderTab
// @ts-ignore
CommonModule.ProductModifiersModal = ProductModifiersModal
CommonModule.AccountAddresses = AccountAddresses
// @ts-ignore
CommonModule.QuickAddTab = QuickAddTab
CommonModule.AccountLayout = AccountLayout
CommonModule.AccountContent = AccountContent
// @ts-ignore
CommonModule.TradeOrderPayment  = TradeOrderPayment
CommonModule.ProductFilterGroupList = ProductFilterGroupList
// @ts-ignore
CommonModule.FulfillmentList = FulfillmentList
// @ts-ignore
CommonModule.CSVAddTab = CSVAddTab;

CommonModule.ForgotPassword = ForgotPassword;

LocationModule.CoreControl.LocationAddress = LocationAddress
LocationModule.CoreControl.LocationDetail = LocationDetail
LocationModule.CoreControl.WhereToBuyLocationCard = WhereToBuyLocationCard
LocationModule.CoreComponents.WhereToBuyLocation = WhereToBuyLocation
// @ts-ignore
LocationModule.CoreControl.LocationMap = LocationMap

ProductModule.CoreComponents.ProductTypeList = CustomProductTypeList
ProductModule.CoreControl.ProductMultiCartButtons = ProductMultiCartButtons
ProductModule.CoreControl.ProductModifiers = ProductModifiers
//@ts-ignore
ProductModule.CoreControl.ProductModifiersModal = ProductModifiersModal
ProductModule.CoreComponents.ProductAttributes = ProductAttributes
ProductModule.CoreComponents.ProductForm = ProductForm
ProductModule.CoreComponents.AddProductToQuoteModal = AddProductToQuoteModal
// @ts-ignore
ProductModule.CoreComponents.ProductDetailHeading = ProductDetailHeading
// @ts-ignore
ProductModule.CoreControl.ProductQuantityInput = ProductQuantityInput

ProductCardModule.CoreControl.ProductCardWithDetail = ProductCardWithDetail
ProductCardModule.CoreControl.SkuCardWithDetail = SkuCardWithDetail
ProductCardModule.CoreControl.ProductCardButton = ProductCardButton
ProductCardModule.CoreControl.SkuCard = SkuCard
ProductCardModule.CoreControl.DropdownButtons = DropdownButtons
ProductCardModule.CoreControl.ProductCard = ProductCard
ProductCardModule.CoreComponents.ProductCardActions = ProductCardActions
ProductListingModule.CoreControl.ListingListView = ListingListView
ProductListingModule.CoreControl.ListingSidebar = ListingSidebar
// @ts-ignore
ProductListingModule.CoreComponents.FacetFilter = FacetFilter

CartModule.CoreComponents.CartLineItem = CartLineItem
CartModule.CoreControl.OrderItemSkuConfigurations = OrderItemSkuConfigurations

PageModule.ProductDetailsPage = ProductDetailPage
PageModule.Blog = Blog
PageModule.DraftOrder = DraftOrder
PageModule.MyAccount = MyAccount

initApp(preload);
//@ts-ignore
const lng = localStorage.getItem("i18nextLng")
if (lng) {
  i18next.changeLanguage(lng)
}



createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
      {/* @ts-ignore */}
      <ServiceContextProvider customServices={{ RouteService, ProductService }}>
        <ElementContextProvider>
          <ComponentContextProvider customComponents={{ cetCtaBtn: CallToActionBtn, cetSearchBox: SearchBar, CommonModule, CartModule, ProductCardModule, ProductModule, PageModule, ProductListingModule, cetHTML: HomeAboutUs, cetCompanyMilestoneConfig: HTMLcontent }}>
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);
