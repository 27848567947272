import { ProductModifier } from "@ultracommerce/react-storefront/global/src/interface/Product";

export interface SelectedSkuConfigurationProps {
  modifiers: ProductModifier[];
  skuConfiguration: { [key: string]: string };
}

const SelectedSkuConfiguration = ({
  modifiers,
  skuConfiguration,
}: SelectedSkuConfigurationProps) => {
  return (
    <details>
      <summary className="py-2">View configuration</summary>
      <div style={{ display : 'grid' ,gridRowGap: '5px'}}>
        {modifiers.map(({ title, key, options }) => {
          const option = options.find(
            (option) => option.code === skuConfiguration[key]
          );
          if (option)
            return (
              <div key={key}>
                <small>
                  <b>{title} </b>: {option.title}
                </small>
              </div>
            );
          return null;
        })}
      </div>
    </details>
  );
};

export { SelectedSkuConfiguration };
