import { ProductService, axios } from "@ultracommerce/react-storefront/global";
import { SearchProduct } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
import { CancelTokenSource } from "axios";
import queryString from "query-string";

const serviceDomain = process.env.REACT_APP_DATASTORE_URL;

const getSearchConfig = () => ({
  productTargetConfig: window.UC?.integrations?.search.productSearchConfigConcern,
  typeaheadTargetConfig: window.UC?.integrations?.search.typeaheadSearchConfigConcern,
});

export class ProjectProductService extends ProductService {
  // eslint-disable-next-line
  constructor(args: any) {
    super(args);
  }

  async search(params: any, type: "product" | "sku", options: { source?: CancelTokenSource } = {}) {
    const servicePath = `/search/query/${type}`;
    const payload = { ...params };
    if (payload.keyword) {
      payload["searchTerm"] = payload.keyword;
    }

    if (payload.currentPage) {
      payload["pageFrom"] = payload.currentPage;
    }

    delete payload["keyword"];
    delete payload["currentPage"];

    if (process.env.REACT_APP_DELTA_API_URL) {
      const searchData: any = {
        facets: [],
        filters: [{ key: "skuModelSearchFlag", value: "No" }],
        query: payload.searchTerm && !isNaN(payload.searchTerm?.slice(0, 3)) ? payload.searchTerm.slice(0, 3) : payload.searchTerm,
        pageFrom: payload.pageFrom,
        route: window.location.pathname,
      };
      this.formatSearchQuery(searchData, payload);
      const { data } = await axios(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search`, {
        data: searchData,
        method: "POST",
        cancelToken: options.source?.token,
      });
      // hydrate products
      let fetchProducts: SearchProduct[] = await Promise.all(
        data.products.map(async (product: any) => {
          return fetch(product.productDataURL).then((resp) => resp.json());
        }),
      );
      data.products = fetchProducts;
      return new this.productSearchFactory(data);
    } else {
      const { data } = await axios(
        serviceDomain +
          servicePath +
          `?${queryString.stringify(
            { concernTarget: getSearchConfig().productTargetConfig, ...payload },
            {
              arrayFormat: "comma",
            },
          )}`,
        {
          cancelToken: options.source?.token,
        },
      );
      return new this.productSearchFactory(data);
    }
  }


  searchTypeahead(
    searchTerm: string,
    typeToSearch: "product" | "sku" | "category" | "productType",
    options: { source?: CancelTokenSource } = {},
    isModelSearch = false
  ) {
    return axios(
      `${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search/typeahead`,
      {
        data: {
          searchTerm,
          filters: !isModelSearch ? [{ key: "skuModelSearchFlag", value: "No" }] : undefined,
        },
        withCredentials: false,
        method: "POST",
        cancelToken: options.source?.token,
      }
    ).then((resp) => {
      return {
        ...resp,
        data: {
          ...resp.data,
          items: resp.data.items.map((item: any) => {
            if (!item.metadata.skuID) item.metadata.skuID = "na";
            return item;
          }),
        },
      };
    });
  }
}
